import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const HistoryPage = () => {
  return (
    <Layout hero={<HeroImage title="History" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <div className="mb-5">
            <Link className="back-to-link" to='/community/about-us'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to about us
            </Link>
          </div>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col sm={12}>
              <p>Orrcon Steel was formed from the merger of Welded Tube Mills and Hills Tubing division in October, 2000.</p>
              <p>In February 2005, Hills Holdings acquired 100% of the Orrcon Steel company. Hills Holdings was founded in 1946 and are makers of the iconic Hills Hoist. 
              In 2007 we acquired the Impressive Group from Western Australia.</p>
              <p>In 2010 we added the SteelBarn business which included the acquisition of five branches in QLD. In February 2014, BlueScope Steel acquired Orrcon Steel from Hills Holdings.</p>
              <p>We are now a wholly owned subsidiary of BlueScope Steel Ltd. BlueScope Steel is a flat product steel producer with operations in Australia, Asia, New Zealand,  North America and Pacific Islands.</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default HistoryPage

export const Head = () => <title>History | Orrcon Steel</title>
